<template>
  <div class="editStatisticalReport">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page">
        <router-link :to="{name: 'OPTIA1000'}">OPTIA 1000</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Poročilo</li>
    </vs-breadcrumb>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
        <h2>Dodajanje poročila</h2>
      </vs-col>
    </vs-row>

    <div class="grid grid-cols-2 gap-10">
      <div class="w-full">


        <div class="vx-card mt-5">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Podatki o poročilu</h4>
            </div>
          </div>

          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <div class="vx-col w-full ">
                <label for="unit" class="vs-input--label">Enota</label>
                <v-select id="unit" :options="unitsData" class="" @input="onUnitChange"
                          v-model="unitId" placeholder="Enota, na katero se poročilo nanaša"
                          :reduce="item => item.id"
                          v-if="unitsData"
                          label="name">

                  <template v-slot:option="option">
                    <vs-list-item
                      :title="option.name"
                      :subtitle="option.coveredMunicipalities">
                      <template slot="avatar" v-if="option.name">
                        <vs-avatar size="large"
                                   :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                                   class="mr-5"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:selected-option="option">
                    <vs-list-item
                      :title="option.name"
                      :subtitle="option.coveredMunicipalities">
                      <template slot="avatar" v-if="option.name">
                        <vs-avatar size="large"
                                   :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                                   class="mr-5"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

              </div>

              <div class="grid grid-cols-2 gap-2 mt-3">
                <div>
                  <label class="vs-input--label">Statistika OD</label>
                  <datepicker placeholder="od" :language="slSI" :format="customDateFormatter" :monday-first="true"
                              v-model="statisticalReportData.start_date"></datepicker>
                </div>
                <div>
                  <label class="vs-input--label">Statistika DO</label>
                  <datepicker placeholder="do" :language="slSI" :format="customDateFormatter" :monday-first="true"
                              v-model="statisticalReportData.end_date"></datepicker>
                </div>
              </div>

              <div class="vx-col w-full mt-5">
                <label for="template" class="vs-input--label">Predloga poročila</label>
                <v-select id="template" :options="templatesData" class="" @input="onTemplateChange"
                          v-model="statisticalReportData.template" placeholder="Predloga za izdelavo poročila"
                          :reduce="item => item.id"
                          label="name">

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

              </div>


              <div class="flex mt-10 mb-5">

                <div class="flex">
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                    <vs-button size="large" color="primary" icon="bar_chart"
                               @click="onGetStatistics">Pridobi statistične
                      podatke
                    </vs-button>
                  </vs-col>
                </div>

              </div>

            </div>

          </div>
        </div>

        <div class="vx-card mt-5" v-if="statistics.dataForSteviloVkljucenihUporabnikov">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Statistični podatki</h4>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <div>
                <h5>Število vključenih uporabnikov</h5>

                <div v-if="statistics && statistics.dataForSteviloVkljucenihUporabnikov">
                  <GChart
                    type="ColumnChart"
                    :data="statistics.dataForSteviloVkljucenihUporabnikov"
                    :options="chartOptions"
                  />

                  <!--                  {{ statistics.dataForSteviloVkljucenihUporabnikov }}-->
                </div>

              </div>

              <div>
                <h5>Skupno število uporabnikov</h5>

                <div v-if="statistics && statistics.dataForSteviloVsehUporabnikov">
                  <GChart
                    type="ColumnChart"
                    :data="statistics.dataForSteviloVsehUporabnikov"
                    :options="chartOptions"
                  />

                  <!--                  {{ statistics.dataForSteviloVkljucenihUporabnikov }}-->
                </div>

              </div>

              <div class="mt-5">
                <h5>Uporabniki po občini stalnega bivališča</h5>

                <div v-if="statistics && statistics.dataForUporabnikiPoObciniStalnegaBivalisca">
                  <GChart
                    type="PieChart"
                    :data="statistics.dataForUporabnikiPoObciniStalnegaBivalisca"
                    :options="pieChartOptions"
                  />

                  <!--                  {{ statistics.dataForUporabnikiPoObciniStalnegaBivalisca }}-->
                </div>
              </div>


              <div class="mt-5">
                <h5>Število opravljenih prevozov</h5>

                <div v-if="statistics && statistics.dataForSteviloOpravljenihPrevozov">
                  <GChart
                    type="ColumnChart"
                    :data="statistics.dataForSteviloOpravljenihPrevozov"
                    :options="chartOptions"
                  />

                  <!--                  {{ statistics.dataForSteviloOpravljenihPrevozov }}-->
                </div>
              </div>

              <div class="mt-5">
                <h5>Število prevoženih kilometrov</h5>

                <div v-if="statistics && statistics.dataForSteviloPrevozenihKilometrov">
                  <GChart
                    type="ColumnChart"
                    :data="statistics.dataForSteviloPrevozenihKilometrov"
                    :options="chartOptions"
                  />

                  <!--                  {{ statistics.dataForSteviloPrevozenihKilometrov }}-->
                </div>
              </div>

              <div class="mt-5">
                <h5>Število prostovoljskih ur</h5>

                <div v-if="statistics && statistics.dataForSteviloProstovoljskihUr">
                  <GChart
                    type="ColumnChart"
                    :data="statistics.dataForSteviloProstovoljskihUr"
                    :options="chartOptions"
                  />

                  <!--                  {{ statistics.dataForSteviloProstovoljskihUr }}-->
                </div>
              </div>

              <div class="mt-5">
                <h5>Mesto odhoda</h5>

                <div v-if="statistics && statistics.dataForUporabnikiPoMestuOdhoda">
                  <GChart
                    type="PieChart"
                    :data="statistics.dataForUporabnikiPoMestuOdhoda"
                    :options="pieChartOptions"
                  />

                  <!--                  {{ statistics.dataForUporabnikiPoMestuOdhoda }}-->
                </div>

              </div>

              <div class="mt-5">
                <h5>Mesto prihoda</h5>

                <div v-if="statistics && statistics.dataForUporabnikiPoMestuDestinacije">
                  <GChart
                    type="PieChart"
                    :data="statistics.dataForUporabnikiPoMestuDestinacije"
                    :options="pieChartOptions"
                  />

                  <!--                  {{ statistics.dataForUporabnikiPoMestuDestinacije }}-->
                </div>

              </div>

              <div class="mt-5" v-if="statisticalReportData.template === 0">
                <h5>Število dogodkov organiziranih skupaj s Toyoto in OKS: <span
                  style="color: #000">{{ statistics.dataForDogodkiToyota }}</span></h5>
              </div>

              <div class="mt-5" v-if="statisticalReportData.template === 0">
                <h5>Napredek (št. prevozov do 1k)</h5>


                <div v-if="statistics && statistics.dataForNapredekDo1k">
                  <GChart
                    type="BarChart"
                    :data="statistics.dataForNapredekDo1k"
                    :options="stackedChartOptions"
                  />

                  <!--                  {{ statistics.dataForNapredekDo1k }}-->
                </div>

              </div>

              <div class="mt-5" v-if="statisticalReportData.template === 1">
                <h5>Delež prevozov glede na glavni namen poti</h5>

                <div v-if="statistics && statistics.reservationsData">
                  <GChart
                    type="PieChart"
                    :data="statistics.dataForPrevoziPoNamenu"
                    :options="pieChartOptions"
                  />

                  <!--                  {{ statistics.dataForUporabnikiPoMestuDestinacije }}-->
                </div>

              </div>


            </div>

          </div>
        </div>

        <div class="vx-card mt-5" v-if="statistics.dataForSteviloVkljucenihUporabnikov">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Besedilna vsebina</h4>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <div class="grid grid-cols-1 mt-5">
                <vs-input class="w-full" label="Naslov poročila"
                          v-model="statisticalReportData.title"/>
              </div>

              <div class="grid grid-cols-1 mt-5">
                <vs-input class="w-full" label="Podnaslov poročila"
                          v-model="statisticalReportData.subtitle"/>
              </div>

              <div class="mt-5" v-if="statisticalReportData.template === 0">
                <label for="summary" class="vs-input--label">Uvod</label>
                <vue-editor v-model="statisticalReportData.summary"></vue-editor>
                <div class="clearfix"></div>
              </div>

              <div class="mt-5" v-if="statisticalReportData.template === 0">
                <label for="summary" class="vs-input--label">Razlaga statističnih podatkov</label>
                <vue-editor v-model="statisticalReportData.data_description"></vue-editor>
                <div class="clearfix"></div>
              </div>

              <div class="mt-5" v-if="statisticalReportData.template === 0">
                <label for="summary" class="vs-input--label">Dobra zgodba</label>
                <vue-editor v-model="statisticalReportData.story"></vue-editor>
                <div class="clearfix"></div>
              </div>

              <div class="mt-5" v-if="statisticalReportData.template === 1">
                <label for="summary" class="vs-input--label">Koordinacija in IT novosti</label>
                <vue-editor v-model="statisticalReportData.coordinationReport"></vue-editor>
                <div class="clearfix"></div>
              </div>


            </div>

          </div>
        </div>

        <div class="vx-card mt-5"
             v-if="statisticalReportData.template === 0 && statistics.dataForSteviloVkljucenihUporabnikov">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Slike</h4>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <div class="flex mt-10 mb-20">
                <div class="w-1/4 photoContainer m-2" v-for="(image, index) in statisticalReportData.photos"
                     :key="'image_' + index">

                  <vs-image :key="index" :src="$globalFunctions.getImage(image.file)"/>
                  <a class="removePhotoBtn" @click="() => { deleteImage(image, index) }">
                    <vs-icon icon="cancel" size="medium"></vs-icon>
                  </a>

                </div>
              </div>

              <div v-if="!statisticalReportData.id">
                <em>Za pripenjanje slik je potrebno poročilo najprej shraniti!</em>
              </div>
              <div v-if="statisticalReportData.id">
                <input type="file" ref="file" @change="fileChanged" accept="image/jpeg, image/png, image/gif"/>
              </div>

            </div>

          </div>
        </div>

        <div class="flex mt-5">
          <div class="w-full">
            <label class="vs-input--label">Opombe k poročilu (vsebina ni vidna v poročilu)</label>
            <vs-textarea class="w-full" rows="6" v-model="statisticalReportData.comment"/>
          </div>
        </div>

        <div class="flex mt-10 mb-20">
          <div class="w-1/2">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
              <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
            </vs-col>
          </div>
          <div class="w-1/2">
            <div class="flex">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button size="large" color="danger" icon="delete" @click="beforeDeleteReport">Izbriši</vs-button>
              </vs-col>

              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button size="large" color="success" icon="save" @click="saveReport">Shrani</vs-button>
              </vs-col>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full">

        <div class="vx-card mt-5" v-if="statisticalReportData">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Predogled poročila</h4>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <vs-button size="medium" class="mb-5" color="success" icon="download" @click="downloadPdf">Prenesi
                pročilo
              </vs-button>

              <div style="max-height: 1200px; overflow: hidden; overflow-y: auto;">
                <StatisticalReportForPreview id="reportPreviewContainer"
                                             :statistical-report-data="statisticalReportData"
                                             :statistics="statistics"></StatisticalReportForPreview>
              </div>

            </div>
          </div>
        </div>

      </div>


    </div>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mt-20" style="background-color: #fff;">
        <img :src="require('@/assets/images/content/other/optia_logos.jpg')" class="bgImg" style="max-height: 100px;">
      </vs-col>
    </vs-row>

  </div>
</template>

<script>
import Vue from 'vue';
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import flatPickr from "vue-flatpickr-component";
import {slSI} from "vuejs-datepicker/dist/locale";
import {VueEditor} from "vue2-editor";
import StatisticalReportForPreview from "@/components/Sopotniki/statisticalReports/StatisticalReportForPreview";
import juice from 'juice';


export default {
  name: 'StatisticalReportEdit',
  components: {
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
    'vue-editor': VueEditor,
    StatisticalReportForPreview
  },

  data() {
    return {
      statisticalReportData: {
        unit_id: null,
        unitId: null,
        unit: null,
        start_date: null,
        end_date: null,
        template: 0,
        title: 'Poročilo Zavoda Sopotniki',
        subtitle: 'O izvajanju projekta S prevozom do 1000 želja',
        summary: null,
        data_description: null,
        story: null,
        photos: null,
        coordinationReport: null,
        comment: null,
      },
      unitsData: [],
      unitId: null,

      templatesData: [{
        id: 0,
        name: 'OPTIA 1000 poročilo'
      },
        {
          id: 1,
          name: '3-mesečno poročilo občinam'
        }
        ],
      templateId: null,

      slSI: slSI,

      statistics: {
        customersData: null,
        dataForSteviloVkljucenihUporabnikov: null,
        dataForSteviloVsehUporabnikov: null,
        dataForUporabnikiPoObciniStalnegaBivalisca: null,
        dataForUporabnikiPoMestuOdhoda: null,
        dataForUporabnikiPoMestuDestinacije: null,
        dataForSteviloOpravljenihPrevozov: null,
        dataForSteviloPrevozenihKilometrov: null,
        dataForSteviloProstovoljskihUr: null,
        dataForPrevoziPoNamenu: null,
        dailyReportData: null,
        reservationsData: null,
        volunteersData: null,
        statsSummary: null,
        introText: null,
        toyotaEventsCount: null,
        reservationsTotalCount: null,
        dataForNapredekDo1k: null,
        dataForDogodkiToyota: null
      },

      chartOptions: {
        title: '',
        subtitle: '',
        height: 380,
        'is3D': false,
        seriesType: 'bars',
      },
      pieChartOptions: {
        title: '',
        subtitle: '',
        height: 380,
        'is3D': false,
        'legend': {position: "right"},
        sliceVisibilityThreshold: 0
      },
      stackedChartOptions: {
        title: '',
        subtitle: '',
        height: 380,
        'is3D': false,
        'legend': 'none',
        isStacked: true,
        seriesType: 'bars',
        bars: 'horizontal'
      },

      activeCustomersData: null,
      refreshIndex: 0,
    }
  },

  watch: {
    // $route (to, from){
    //   this.getReportData();
    // }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();

    let p1 = this.getUnits();

    Promise.all([p1]).then(() => {

      if (_this.$route.params.statisticalReportId) {
        _this.getReportData();
      } else {
        _this.$vs.loading.close();
      }
    });

  },

  // beforeRouteLeave(to, from, next) {
  //   // console.log("beforeRouteLeave");
  //   // const _this = this;
  //   // _this.unitId = null;
  //   // _this.sidebarActive = false;
  //   // setTimeout(() => {next()}, 100);
  // },

  methods: {
    onUnitChange() {
      const _this = this;
      _this.$set(_this.statisticalReportData, 'unit_id', _this.unitId);
      _this.$set(_this.statisticalReportData, 'unitId', _this.unitId);
      _this.$forceUpdate();
    },

    onTemplateChange() {
      const _this = this;
      console.log("_this.statisticalReportData.template", _this.statisticalReportData.template);
      if (_this.statisticalReportData.template === 1) {
        _this.statisticalReportData.title = 'Poročilo Zavoda Sopotniki';
        _this.statisticalReportData.subtitle = 'O izvajanju storitve brezplačnih prevozov za starejše';
      } else {
        _this.statisticalReportData.title = 'Poročilo Zavoda Sopotniki';
        _this.statisticalReportData.subtitle = 'O izvajanju projekta S prevozom do 1000 želja';
      }
    },

    async getReportData() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'statistical-report/' + _this.$route.params.statisticalReportId)
        .then((res) => {

          _this.statisticalReportData = res.data.data;
          _this.$set(_this.statisticalReportData, 'unit_id', res.data.data.unit.id);
          _this.$set(_this.statisticalReportData, 'unitId', res.data.data.unit.id);
          _this.$set(_this.statisticalReportData, 'template', parseInt(res.data.data.template));
          _this.unitId = res.data.data.unit.id;
          console.log("_this.statisticalReportData", _this.statisticalReportData);

          _this.getStatistics().then(() => {
            _this.getCustomers().then(() => {
              _this.statistics.statsSummary = _this.formatDataForStatsSummary();
              _this.statistics.introText = _this.formatDataForIntroText();
              _this.$forceUpdate();
              _this.$vs.loading.close();
            })
          });


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {

          _this.unitsData = _.filter(res.data.data, {disabled: 0});
          // _this.unitsData.push({
          //   id: -1,
          //   name: 'Sedež Zavoda Sopotniki',
          //   coveredMunicipalities: 'Sedež Zavoda Sopotniki'
          // })
          // console.log("unitsData: ", res.data.data);


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    async getCustomers() {
      const _this = this;

      if (!_this.$vs.loading) {
        _this.$vs.loading();
      }

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.unitId)
        .then((res) => {
          _this.activeCustomersData = _.filter(res.data.data, {active: 1});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    onGetStatistics() {
      const _this = this;

      if (!_this.statisticalReportData.start_date || !_this.statisticalReportData.end_date) {
        this.$confirm(
          {
            title: 'Manjkajoči podatki',
            message: 'Določite časnovno obdobje!',
            button: {
              yes: 'OK',
            }
          });
        return
      }

      if (!_this.unitId) {
        this.$confirm(
          {
            title: 'Manjkajoči podatki',
            message: 'Določite enoto!',
            button: {
              yes: 'OK',
            }
          });
        return
      }

      _this.$vs.loading();
      _this.getStatistics().then(() => {
        _this.statistics.statsSummary = _this.formatDataForStatsSummary();
        _this.statistics.introText = _this.formatDataForIntroText();
        _this.$forceUpdate();
        _this.refreshIndex++;
        _this.$vs.loading.close();
      });
    },

    async getStatistics() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/daily_report/' + _this.unitId,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then((res) => {
          _this.statistics.dailyReportData = res.data.data;
          _this.statistics.dataForSteviloPrevozenihKilometrov = _this.formatDataForSteviloPrevozenihKilometrov(res.data.data);
          _this.statistics.dataForSteviloProstovoljskihUr = _this.formatDataForSteviloProstovoljskihUr(res.data.data);
          _this.$forceUpdate();
        });

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/customers/' + _this.unitId,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then((res) => {
          _this.statistics.customersData = res.data.data;
          _this.statistics.dataForUporabnikiPoObciniStalnegaBivalisca = _this.formatDataForUporabnikiPoObciniStalnegaBivalisca(res.data.data);
          _this.$forceUpdate();
        });

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/reservations/' + _this.unitId,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then(async(res) => {
          _this.statistics.reservationsData = res.data.data;
          _this.statistics.dataForSteviloVkljucenihUporabnikov = _this.formatDataForSteviloVkljucenihUporabnikov(res.data.data);
          _this.statistics.dataForSteviloOpravljenihPrevozov = _this.formatDataForSteviloOpravljenihPrevozov(res.data.data);
          _this.statistics.dataForUporabnikiPoMestuOdhoda = _this.formatDataForUporabnikiPoObciniStalnegaBivalisca(res.data.data.departure);
          _this.statistics.dataForUporabnikiPoMestuDestinacije = _this.formatDataForUporabnikiPoObciniStalnegaBivalisca(res.data.data.destination);
          _this.statistics.dataForPrevoziPoNamenu = _this.formatDataForPrevoziPoNamenu(res.data.data);
          _this.statistics.dataForSteviloVsehUporabnikov = await _this.formatDataForSteviloVsehUporabnikov(res.data.data);
          _this.$forceUpdate();
        });

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/volunteersOverview/' + _this.unitId,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then((res) => {
          console.log("volunteersOverview", res);
          _this.statistics.volunteersData = res.data;
          _this.$forceUpdate();
        });


      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'reservations/count/',
        {
          params: {
            unit: _this.unitId,
            end: _this.moment(_this.statisticalReportData.end_date).valueOf(),
          }
        }
      )
        .then((res) => {
          _this.reservationsTotalCount = res.data.data;
          _this.statistics.dataForNapredekDo1k = _this.formatDataForNapredekDo1k(res.data.data);
          _this.$forceUpdate();
        });

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'reservations/count/',
        {
          params: {
            unit: _this.unitId,
            end: _this.moment(_this.statisticalReportData.end_date).valueOf(),
            toyotaEvent: true
          }
        }
      )
        .then((res) => {
          _this.statistics.dataForDogodkiToyota = res.data.data;
          _this.$forceUpdate();
        });


      // reservationsTotalCount

    },

    async saveReport() {
      const _this = this;
      console.log("save Report");

      _this.$vs.loading();

      let data = {
        "title": _this.statisticalReportData.title,
        "subtitle": _this.statisticalReportData.subtitle,
        "start_date": _this.moment(_this.statisticalReportData.start_date).valueOf(),
        "end_date": _this.moment(_this.statisticalReportData.end_date).valueOf(),
        "template": _this.statisticalReportData.template ? _this.statisticalReportData.template.toString() : '0',
        "summary": _this.statisticalReportData.summary,
        "data_description": _this.statisticalReportData.data_description,
        "story": _this.statisticalReportData.story,
        "unit": _this.unitId,
        "coordinationReport": _this.statisticalReportData.coordinationReport,
        "comment": _this.statisticalReportData.comment,
      }

      console.log("data", data);

      if (_this.$route.params.statisticalReportId) {
        data.id = _this.$route.params.statisticalReportId;
        await Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'statistical-report/' + _this.$route.params.statisticalReportId, data)
          .then((res) => {
            _this.$vs.loading.close();
            // _this.statistics.reservationsData = res.data;
            _this.$vs.notify({
              title: 'Poročilo uspešno shranjeno',
              text: 'Lahko nadaljujete z urejanjem.',
              iconPack: 'feather',
              icon: 'icon-ok-circle',
              color: 'success',
              position: 'top-right'
            })

          });
      } else {
        await Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'statistical-report', data)
          .then((res) => {

            _this.$vs.notify({
              title: 'Poročilo uspešno shranjeno',
              text: 'Lorem ipsum',
              iconPack: 'feather',
              icon: 'icon-ok-circle',
              color: 'success'
            })

            _this.$router.push({path: '/optia-1000/porocilo/' + res.data.data.id});
            _this.$vs.loading.close();
          });
      }


    },

    downloadPdf() {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/pdf-statistical-report/' + _this.statisticalReportData.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token + '&t=' + _this.moment().valueOf()),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `statisticno_porocilo.pdf`
          link.click();
          _this.$vs.loading.close();
        });
    },

    formatDataForStatsSummary() {
      const _this = this;

      let numberOfTransportations = 0;


      _.each(_this.statistics.reservationsData.by_months, function (val, key) {
        numberOfTransportations += val.length;
      });
      console.log("numberOfTransportations", numberOfTransportations);
      let averageDisatancePerTransportation = parseInt(_this.statistics.reservationsData.route_distance_total / numberOfTransportations).toFixed();

      const totalNumberOfUsersAtTheEndOfReport = _this.statistics.dataForSteviloVsehUporabnikov[_this.statistics.dataForSteviloVsehUporabnikov.length-1][2];
      // console.log("totalNumberOfUsersAtTheEndOfReport", totalNumberOfUsersAtTheEndOfReport);

      let statsSummary = 'V obdobju od ' + _this.moment(_this.statisticalReportData.start_date).format('D. M. YYYY') + ' do ' + _this.moment(_this.statisticalReportData.end_date).format('D. M. YYYY') +
        ' smo opravili ' + numberOfTransportations + ' prevozov in prevozili ' + _this.statistics.dailyReportData.odometer_total + ' kilometrov. Vozniki prostovoljci so opravili ' + _this.statistics.dailyReportData.volunteer_hours_total + ' ur prostovoljskega dela. <br/>' +
        'Povprečna razdalja prevoza je bila ' + averageDisatancePerTransportation + ' kilometrov. <br/>' +
        'V bazo uporabnikov smo do ' + _this.moment(_this.statisticalReportData.end_date).format('D. M. YYYY') + ' vpisali ' + totalNumberOfUsersAtTheEndOfReport + ' aktivnih uporabnikov storitve.';

      return statsSummary;
    },

    formatDataForIntroText() {
      const _this = this;

      let activeVolunteers = [];
      _.each(_this.statistics.volunteersData.by_months, function (val, key) {
        _.each(val, function (_val, _key) {
          if (activeVolunteers.indexOf(_val.volunteers_id) < 0) {
            activeVolunteers.push(_val.volunteers_id);
          }
        });
      });

      const totalNumberOfUsersAtTheEndOfReport = _this.statistics.dataForSteviloVsehUporabnikov[_this.statistics.dataForSteviloVsehUporabnikov.length-1][2];
      // console.log("totalNumberOfUsersAtTheEndOfReport", totalNumberOfUsersAtTheEndOfReport);

      let statsIntroText = 'V obdobju od ' + _this.moment(_this.statisticalReportData.start_date).format('D. M. YYYY') + ' do ' + _this.moment(_this.statisticalReportData.end_date).format('D. M. YYYY') + ' je v enoti ' + (_this.statisticalReportData.unit ? _this.statisticalReportData.unit.name : '') + ' - ' + (_this.statisticalReportData.unit ? _this.statisticalReportData.unit.coveredMunicipalities : '') + ' na terenu aktivno sodelovalo ' + activeVolunteers.length + ' prostovoljcev. V bazo uporabnikov smo sprejeli ' + totalNumberOfUsersAtTheEndOfReport + ' starejših občanov.<br/><br/>';

      _.each(_this.statistics.volunteersData.by_months, function (val, key) {
        statsIntroText += _this.formatDataForIntroTextPerMonth(key);
      });

      return statsIntroText;
    },

    formatDataForIntroTextPerMonth(month) {
      const _this = this;
      console.log("month", month);

      let monthName = null;

      switch (parseInt(month)) {
        case 0:
          monthName = "januarju";
          break;
        case 1:
          monthName = "februarju";
          break;
        case 2:
          monthName = "marcu";
          break;
        case 3:
          monthName = "aprilu";
          break;
        case 4:
          monthName = "maju";
          break;
        case 5:
          monthName = "juniju";
          break;
        case 6:
          monthName = "juliju";
          break;
        case 7:
          monthName = "avgustu";
          break;
        case 8:
          monthName = "septembru";
          break;
        case 9:
          monthName = "oktobru";
          break;
        case 10:
          monthName = "novembru";
          break;
        case 11:
          monthName = "decembru";
          break;
      }

      let steviloPrevozov = _this.statistics.reservationsData.by_months[parseInt(month)].length;
      let steviloUporabnikov = [];

      _.each(_this.statistics.reservationsData.by_months[parseInt(month)], function (val, key) {
        if (steviloUporabnikov.indexOf(val.customer_id) < 0) {
          steviloUporabnikov.push(val.customer_id);
        }
      });


      let odometerTotal = 0;
      let volunteerHoursTotal = 0;
      let volunteerMinutesTotal = 0;
      _.each(_this.statistics.dailyReportData.by_months[parseInt(month)], function (val, key) {
        odometerTotal += parseInt(val.odometer_total ? val.odometer_total : 0);
        volunteerHoursTotal += (val.volunteer_hours_total ? parseInt(val.volunteer_hours_total.split(':')[0]) : 0);
        volunteerMinutesTotal += (val.volunteer_hours_total ? parseInt(val.volunteer_hours_total.split(':')[1]) : 0);
      });
      let additionalHours = Math.floor(volunteerMinutesTotal / 60);
      let minutes = volunteerMinutesTotal % 60;
      volunteerHoursTotal += additionalHours + (minutes >= 30 ? 1 : 0);

      let numberOfActiveCustomersPerMonth = 0;
      _.each(_this.statistics.dataForSteviloVsehUporabnikov, function (val, key) {
        if(val[0].includes(_this.moment().month(month).format('MMMM'))){
          numberOfActiveCustomersPerMonth = val[2];
        }
      });

      let statsIntroTextPerMonth = 'V ' + monthName + ' smo opravili ' + steviloPrevozov + ' prevozov (en prevoz pomeni povratno vožnjo) in prepeljali ' + steviloUporabnikov.length + ' različnih uporabnikov' + (steviloPrevozov > steviloUporabnikov.length ? ', kar pomeni, da so nekateri storitev koristili večkrat.' : '.') + ' Prostovoljci so bili na terenu ' + volunteerHoursTotal + ' ur in v tem času prevozili ' + odometerTotal + ' kilometrov. V bazo uporabnikov smo sprejeli ' + numberOfActiveCustomersPerMonth + ' starejših občanov.<br/><br/>';

      return statsIntroTextPerMonth;

    },

    formatDataForSteviloVkljucenihUporabnikov(reservationsData) {
      const _this = this;

      console.log("reservationsData", reservationsData)

      let _data = [];
      _data.push(['Mesec', 'Število vključenih uporabnikov', { role: 'annotation' }]);

      if (reservationsData && Object.keys(reservationsData.by_months).length) {
        _.each(reservationsData.by_months, function (val, idx) {
          let month = _this.moment(val[0].departure_time).format("MMMM, YYYY");
          let _val = _.uniqBy(val, function (e) {
            return e.customer_id;
          });
          _data.push([month, _val.length, _val.length]);
        });
      } else {
        _data.push(['', 0]);
      }
      return _data
    },

    async formatDataForSteviloVsehUporabnikov(customersData) {
      const _this = this;

      let _data = [];
      _data.push(['Mesec', 'Skupno število uporabnikov', { role: 'annotation' }]);

      let months = customersData.by_months;
      let year = _this.moment(_this.statisticalReportData.start_date).format('YYYY');

      _this.getCustomers().then(() => {
        for (const month of Object.keys(months)) {
          let customersPerMonth = [];
          _.each(_this.activeCustomersData, function (val, key) {
            if (_this.moment(val.created_at) <= _this.moment().year(year.toString()).month(month)) {
              customersPerMonth.push(val);
            }
          });
          _data.push([_this.moment().month(month).format('MMMM') + ', ' + year, customersPerMonth.length, customersPerMonth.length]);
        }
      });
      return _data;
    },

    formatDataForUporabnikiPoObciniStalnegaBivalisca(customersData) {
      let _data = [];
      _data.push(['Občina', 'Število uporabnikov']);

      _.each(customersData, function (val) {
        _data.push([val.name + ' (' + val.count + ')', val.count]);
      });

      return _data
    },

    formatDataForPrevoziPoNamenu(reservationsData) {
      const _this = this;

      let _data = [];
      _data.push(['Namen', 'Število prevozov']);
      let results = {};


      if (reservationsData && Object.keys(reservationsData.by_months).length) {
        _.each(reservationsData.by_months, function (val, idx) {

          _.each(val, function (_val, _key) {
            if (results[_this.$globalFunctions.getReservationPurposeLabel(_val.reservation_purpose)]) {
              results[_this.$globalFunctions.getReservationPurposeLabel(_val.reservation_purpose)]++;
            } else {
              results[_this.$globalFunctions.getReservationPurposeLabel(_val.reservation_purpose)] = 1;
            }
          });
        });
      } else {
        _data.push(['', 0]);
      }
      _.each(results, function (val, key) {
        _data.push([key + ' (' + val + ')', val]);
      })

      return _data
    },

    formatDataForSteviloOpravljenihPrevozov(reservationsData) {
      const _this = this;

      // get cars for unit
      let cars = _this.$globalFunctions.getUnitDataById(_this.unitId).cars.filter((car) => { return !car.name.includes('ni več aktivno') });

      let _data = [];

      if(cars.length === 1) {
        _this.chartOptions.legend = 'none';

        _data.push(['Mesec', 'Število prevozov', {role: 'annotation'}]);

        if (reservationsData && Object.keys(reservationsData.by_months).length) {
          _.each(reservationsData.by_months, function (val, idx) {
            let month = _this.moment(val[0].departure_time).format("MMMM, YYYY");
            _data.push([month, val.length, val.length]);
          });
        } else {
          _data.push(['', 0, 0]);
        }
      } else {

        _data.push(['Mesec']);
        _.each(cars, function (val, idx) {
          if(val.name !== null) {
            _data[0].push(val.name);
            _data[0].push({role: 'annotation'});
          }
        });
        _data[0].push('Skupaj');
        _data[0].push({role: 'annotation'});

        if (reservationsData && Object.keys(reservationsData.by_months).length) {
          _.each(reservationsData.by_months, function (val, idx) {
            let month = _this.moment(val[0].departure_time).format("MMMM, YYYY");
            let numberOfTransportationsByCarsByMonth = [];
            let totalNumberOfTransportationsByMonth = val.length;
            _.each(cars, function (_val, _idx) {
              let count = _.filter(val, {car_id: _val.id}).length;
              numberOfTransportationsByCarsByMonth.push(count, count);
            });
            _data.push(_.concat([month], numberOfTransportationsByCarsByMonth, [totalNumberOfTransportationsByMonth], [totalNumberOfTransportationsByMonth]));
          })
        }
      }

      return _data
    },

    formatDataForSteviloPrevozenihKilometrov(dailyReportsData) {
      const _this = this;

      console.log("dailyReportsData", dailyReportsData);

      // get cars for unit
      //let cars = _this.$globalFunctions.getUnitDataById(_this.unitId).cars;
      let cars = _this.$globalFunctions.getUnitDataById(_this.unitId).cars.filter((car) => { return !car.name.includes('ni več aktivno') });

      let _data = [];

      // single car unit
      if(cars.length === 1) {

        _this.chartOptions.legend = 'none';

        _data.push(['Mesec', 'Število prevoženih kilometrov', {role: 'annotation'}]);

        _.each(dailyReportsData.by_months, function (val, idx) {
          let month = _this.moment(val[0].date).format("MMMM, YYYY");
          let odometerTotal = 0;

          _.each(val, function (_val, _idx) {
            odometerTotal += parseInt(_val.odometer_total ? _val.odometer_total : 0);
          });

          _data.push([month, odometerTotal, odometerTotal]);
        });

        // multiple cars unit
      } else {

        _data.push(['Mesec']);

        _.each(cars, function (val) {
          _data[0].push(val.name);
          _data[0].push({role: 'annotation'});
        });
        _data[0].push('Skupaj');
        _data[0].push({role: 'annotation'});

        let byCarsAndMonths = [];
        _.each(dailyReportsData.odometer_by_car_by_month, function (car, idx) {

          _.each(car.odometer_by_month, function (_val, _key){
            let date = _val.month.split('-')[0] + '-' + (parseInt(_val.month.split('-')[1]) + 1);
            let month = _this.moment(date, 'YYYY-M').format("MMMM, YYYY");

            if(car.car_id !== 'null') {
              byCarsAndMonths.push({
                'car': car.car_id,
                'month': month,
                'odometerTotal': parseInt(_val.odometer_total ? _val.odometer_total : 0)
              });
            }

          });
        });

        let startMonth = _this.moment(_this.statisticalReportData.start_date).month();
        let endMonth = _this.moment(_this.statisticalReportData.end_date).month();
        let year = _this.moment(_this.statisticalReportData.start_date).year();

        for (let i = startMonth; i <= endMonth; i++) {
          let _month = _this.moment().month(i).year(year).format('MMMM, YYYY');

          let kilometersByCars = [];
          _.each(cars, function (_val, _idx) {
            let distance = _.filter(byCarsAndMonths, {car: _val.id.toString()});
            distance = _.filter(distance, {month: _month}).length > 0 ?  _.filter(distance, {month: _month})[0].odometerTotal : 0;

            kilometersByCars.push(distance, distance);
          });
          let sum = _.sum(kilometersByCars)/2;
          _data.push(_.concat([_month], kilometersByCars, sum, sum));
        }
      }

      console.log("_data - število prevoženih kilometrov", _data);

      return _data
    },

    formatDataForSteviloProstovoljskihUr(dailyReportsData) {
      const _this = this;

      console.log("dailyReportsData", dailyReportsData);
      let _data = [];
      _data.push(['Mesec', 'Število prostovoljskih ur', {role: 'annotation'}]);

      _.each(dailyReportsData.by_months, function (val, idx) {
        let month = _this.moment(val[0].date).format("MMMM, YYYY");
        let volunteerHours = 0;
        let volunteerMinutes = 0;

        _.each(val, function (_val, _idx) {
          volunteerHours = (_val.volunteer_hours_total ? parseInt(_val.volunteer_hours_total.split(':')[0]) : 0) + volunteerHours;
          volunteerMinutes = (_val.volunteer_hours_total ? parseInt(_val.volunteer_hours_total.split(':')[1]) : 0) + volunteerMinutes;
        });

        let additionalHours = Math.floor(volunteerMinutes / 60);
        let minutes = volunteerMinutes % 60;

        _data.push([month, _this.timeToDecimal(volunteerHours + additionalHours + ':' + minutes), _this.timeToDecimal(volunteerHours + additionalHours + ':' + minutes)]);
      });

      console.log("_data - število prostovoljskih ur", _data);

      return _data
    },

    timeToDecimal(t) {
      let arr = t.split(':');
      let dec = parseInt((arr[1] / 6) * 10, 10);

      return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
    },

    formatDataForNapredekDo1k(count) {
      let _data = [
        ['Prevozi', 'že opravljeni prevozi', 'še ne opravljeni prevozov'],
        ['prevozi', count, (1000 - count <= 0) ? 0 : (1000 - count)],
      ];

      return _data;
    },


    fileChanged() {
      const _this = this;

      // _this.$vs.loading();

      let file = _this.$refs.file;
      console.log(file.files[0]);

      let formData = new FormData();
      formData.append('files', file.files[0]);

      _this.saveReport().then(() => {
        _this.$vs.loading();
        Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'statistical-report/photos/' + _this.$route.params.statisticalReportId, formData)
          .then((res) => {
            _this.getReportData();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      })
    },

    beforeDeleteReport() {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati statistično poročilo z naslovom ' + _this.statisticalReportData.title + '?',
        message: 'Po potrditvi bo dnevno poročilo trajno izbrisano.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteReport();
          }
        }
      });
    },

    async deleteReport() {
      const _this = this;

      _this.$vs.loading();

      await Vue.prototype.$http.delete(`${Vue.prototype.$config.api.sopotnikiVPS}statistical-report/${_this.statisticalReportData.id}`)
        .then((res) => {

          console.log('dailyReport deleted');
          _this.$vs.loading.close();
          _this.$router.push({name: 'OPTIA1000'});

        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });

    },

    async deleteImage(image, index) {
      const _this = this;

      console.log("image", image);
      await Vue.prototype.$http.delete(`${Vue.prototype.$config.api.sopotnikiVPS}statistical-report/photos/${image.file}`)
        .then((res) => {

          _this.statisticalReportData.photos.splice(index, 1);
          _this.$vs.loading.close();


        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });


    }
  }
}
</script>

